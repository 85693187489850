<template>
  <div class="quote-panel" style="margin:50px;">
    <b-button size="sm" variant="primary" @click="testGating" :disabled="!hasInit">Test Gating (must be ready to bind)</b-button> Gating on id: {{curId}}
    <h3 class="mt-3">EventBus Queue</h3>
    <pre>{{queue.length ? queue.map(q=>q.operation).join('\n') : '[empty queue]'}}</pre>
    <h3 class="mt-3">Log</h3>
    <pre v-for="([time,log],i) in logs" :key="time + i">{{new Date(time).toLocaleTimeString().split(' ')[0]}}:{{time%1000}} {{log}}</pre>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import eventbus from '@/lib/eventbus';

export default {
  data: () => {
    return {
      logs: [],
      curId: eventbus.curQuoteId,
      queue: eventbus.activeQuoteQueue,
      hasInit: false,
      finalCall: true
    };
  },
  computed: {
    quoteId(){
      return this.$route.params.quoteId;
    }
  },
  name: 'OpenQuote',
  mixins: [quotemix],
  methods: {

    log(s){
      this.logs.push([Date.now(), s]);
      //console.log(s);
    },
    open(){
      return new Promise(res => {
        this.log(`[call] getQuoteDetails(${this.quoteId})`);
        this.oneShield('getQuoteDetails', {id: this.quoteId}).then(result => {
          if (result?.response?.error){
            this.log(`[error] updateBuildings (${result.response.error.message})`);
            res(false);
          }else {
            this.rehydrate(result.response);
            this.log(`[complete] getQuoteDetail(${this.quoteId})`);
            res(true);
          }

        });
      });

    },
    deleteDocuments(docIds){
      let {quoteId} = this;
      docIds.forEach(documentId => {
        this.log(`[call] deleteDocument(${documentId})`);
        this.oneShield('deleteDocument', {quoteId, documentId}).then(result => {
          if (result?.response?.error){
            this.log(`[error] deleteDocument(${documentId}) (${result.response.error.message})`);
          }else {
            this.log(`[complete] deleteDocument(${documentId})`);
          }
        });
      });
    },
    setBuildingCt(val = 1){
      let chain = `${this.buildings[0].chain}.identicalCt`;
      this.log(`[call] updateBuildings (ct=${val})`);
      this.updateField({chain, val});
      this.saveBuildingsByGroup('construction').then(result => {
        if (result?.response?.error){
          this.log(`[error] updateBuildings (${result.response.error.message})`);
        }else {
          this.log(`[complete] updateBuildings(identicalCt=${val})`);
        }
      });
    },
    getDocumentsList(){
      this.log('[call] getDocumentList');
      this.oneShield('getDocumentList', {quoteId: this.quoteId}).then(result => {
        if (result.response.error){
          this.log(`[error] documentList (${result.response.error.message})`);
        }else {
          let dox = result.response.fields[0].val;
          this.log(`[complete] documentList: ${dox.map(d => d.fileName)}`);
          this.deleteDocuments(dox.map(d => d.documentId));
        }
      });
    },
    genProposal(){
      this.log('[call] generateProposal');
      this.oneShield('generateProposal', {quoteId: this.quoteId}).then(result => {
        if (result.response.error){
          this.log(`[error] generateProposal (${result.response.error.message})`);
        }else {
          this.log('[complete] generateProposal');

          this.getDocumentsList();
        }
      });
    },
    jurisdictionList(){
      this.log('[call] jurisdictionList');
      this.oneShield('jurisdictionList', {}).then(result => {
        if (result.response.error){
          this.log(`[error] jurisdictionList (${result.response.error.message})`);
        }else {
          this.log('[complete] jurisdictionList');
        }
      });
    },
    testGating(){
      this.logs.splice(0, this.logs.length);
      this.jurisdictionList();
      this.open();
      this.setBuildingCt(3);
      /*this.jurisdictionList();
      this.setBuildingCt(5);
      this.jurisdictionList();
      this.jurisdictionList();
      this.genProposal();
      this.setBuildingCt(2);
      this.setBuildingCt(1);*/
      this.finalCall = false;
    },
    initialize(){
      eventbus.setActiveQuote(this.quoteId);

      this.open().then((hasInit) => {
        this.hasInit = hasInit;
      });

    },
    reset(){
      this.logs.splice(0, this.logs.length);
      this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  watch: {
    queue(q){
      if (!this.finalCall && q.length === 0){
        this.finalCall = true;
        this.getDocumentsList();
      }
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
pre{
  background-color: $light;
  border:solid 1px $gray;
  font-family: Consolas, Menlo, Courier, monospace;
  padding:4px 8px;
  margin:0 0 4px 0;
}
</style>
